  @import "bootstrap/scss/bootstrap-grid.scss";
  @import "./variables.scss";
  @import "./theme.scss";
  @import 'ngx-toastr/toastr';
  @import 'tippy.js/dist/tippy.css';
  @import "primeng/resources/themes/lara-light-blue/theme.css";
  @import "primeng/resources/primeng.css";

  /* You can add global styles to this file, and also import other style files */

  /********** theme colors implementations **********/
  /*
  example usage:
  <div class="mm-theme-background-primary">
    This is a div with a primary background color.
  </div>

  <p class="mm-theme-text-danger">
    This is a paragraph with danger text color.
  </p>
  */
  @include media-breakpoint-up(md) {
    .full-height-md {
      height: 100vh;

    }
  }

  .selected-item-container {
    visibility: hidden !important;
    height: 25px !important;
  }

  .dropdown-btn {
    border: 1px solid #C7C7C7;
    border-radius: 10px !important;
    background: transparent;
    height: 3.5rem !important;
    color: #434343;
    display: flex !important;
    align-items: center !important;

    .dropdown-multiselect__caret::before {
      top: 105% !important;
    }
  }

  .container-custom {
    max-width: 1600px !important;
  }

  .pe-auto {
    cursor: pointer;
  }

  .mm-theme {
    &-spacer {
      @include media-breakpoint-up(md) {
        height: 10vh;
      }
    }

    &-checkbox {
      &.form-check-input {
        background-color: white;
        border-color: white;
        border: solid 2px white;
        -webkit-box-shadow: 0px 0px 0px 2px $primary-color;
        -moz-box-shadow: 0px 0px 0px 2px $primary-color;
        box-shadow: 0px 0px 0px 2px $primary-color;

        &:checked {
          background-color: $primary-color;
          background: $primary-strong-color;
        }
      }
    }

    &-background {
      &-primary {
        background-color: $primary-color;
        color: white;
      }

      &-secondary {
        background-color: $secondary-color;
        color: white;
      }

      &-secondary:active {
        background-color: $secondary-color !important;
      }

      &-danger {
        background-color: $danger-color;
        color: white;
      }

      &-warning {
        background-color: $warning-color;
        color: white;
      }

      &-info {
        background-color: $info-color;
        color: white;
      }

      &-light {
        background-color: $light-color;
        color: black;
      }

      &-dark {
        background-color: $dark-color;
        color: white;
      }
    }

    &-text {
      &-primary {
        color: $primary-color !important;
      }

      &-primary-strong {
        color: $primary-strong-color;
      }

      &-secondary {
        color: $secondary-color !important;
      }

      &-danger {
        color: $danger-color !important;
      }

      &-warning {
        color: $warning-color !important;
      }

      &-info {
        color: $info-color !important;
      }

      &-light {
        color: $light-color !important;
      }

      &-lighter {
        color: $lighter-color !important;
      }

      &-light-opaque {
        color: $light-opaque-color;
      }

      &-lighter-opaque {
        color: $lighter-opaque-color;
      }

      &-dark {
        color: $dark-color !important;
      }
    }

    &-light-border-color {
      border: solid 1px $light-border-color;
    }
  }

  * {
    font-family: "Poppins", sans-serif;
  }

  a {
    text-decoration: none;
    color: black;
  }

  .links {
    color: #AD4891;
    text-decoration: underline;
  }


  .owl-nav {
    display: none !important;
  }

  .owl-dots {
    position: relative;
    top: -100px;

    .owl-dot {
      span {
        background-color: #d9d9d9a3;
      }
    }

    .owl-dot.active {
      span {
        background-color: #ffbb00 !important;
      }
    }
  }

  .explore-apartment-slider .owl-stage-outer,
  .testimonial-slider .owl-stage-outer {
    display: inline-block;
  }

  .testimonial-slider {
    position: relative;

    .owl-stage-outer {
      display: inline-block;
    }

  }

  .product-slider {
    position: relative;

    @media (max-width: 1024px) {
      .owl-stage-outer {
        display: inline-block;
      }
    }

  }


  .group-title {
    font-size: 1.8rem;
    font-weight: 500;
  }


  /* STYLE CARD */
  .card {
    padding: 20px;
    border-radius: 10px;
    border: none;
  }

  /* MARGIN CUSTOM */

  .mt-custom {
    margin-top: 10%;
  }


  /* BUTTON */
  .btn-meet {
    background-color: #9e3e84;
    color: white;

    &:disabled {
      background-color: #ccc;
      color: #888;
      cursor: not-allowed;
    }
  }

  /* NOTIFICATION */

  .notification-custom {
    z-index: 9999;
    top: 10px;
  }


  /* FORM */

  .mm-theme-input {
    margin-top: 15px;
    border: 1px solid #C7C7C7
  }

  .btn-meet {
    margin-top: 15px;
    width: 100%;
    height: 56px;
  }

  /* TREE SELECTOR  */
  .p-treeselect {
    display: inline-flex;
    cursor: pointer;
    position: relative;
    -webkit-user-select: none;
    user-select: none;
    align-items: center;
    font-family: "Poppins";
    padding: 1rem 1rem 1rem 1.5rem;
  }

  .prime-selector {

    display: flex !important;
    width: 100%;
    padding: 0.375rem 0.375rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    appearance: none;
    background-color: var(--bs-body-bg);
    background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .prime-selector .ui-treenode-label {
    cursor: pointer;
  }

  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
    background: #EFF6FF;
    color: #ad4891;
  }

  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler:hover,
  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon:hover {
    color: #ad4891;
  }

  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler,
  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
    color: #ad4891;
  }

  chevrondownicon {
    rotate: -90deg;
  }

  .special-margin p {
    margin: 0px !important;
  }

  /* P-TREE CUSTOM RESPONSIVE MENU */
  .prime-selector-custom {
    display: flex !important;
    width: 100%;
    padding: 0.375rem 0.375rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    appearance: none;
    background-color: #470033;
    background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: 30px !important;


    .p-treeselect-label-container {
      .p-placeholder {
        color: #EFF6FF !important;
      }

      .p-treeselect-label {
        color: #EFF6FF;
      }
    }

    .p-tree {
      background: #470033;
      color: #EFF6FF
    }
  }



  /* Prime Emoji Picker */
  .p-overlaypanel.p-overlaypanel-flipped:before {
    border-top-color: #d1d5db !important;
  }

  .emoji-wrapper {
    border: none !important;
  }

  /* x icon in category menu */
  .btn-close.menu {
    --bs-btn-close-color: #ffff;
    --bs-btn-close-bg: url('/assets/img/x_icon.svg') !important;
  }

  .BTN_PURPLE_LG {
    background: #76215D;
    color: white;
  }

  .BTN_PURPLE_LG:hover {
    background: #76215D !important;
    opacity: 0.90;
    color: white;
  }

  .BTN_LIGHT_LG {
    background: transparent;
    border: 1px solid $primary-color;
    color: $primary-color;
  }

  .BTN_LIGHT_LG:hover {
    background: transparent;
    border: 1px solid $primary-color;
    color: $primary-color;
    opacity: 0.90;
  }

  /* x icon in category menu */
  .btn-close.menu {
    --bs-btn-close-color: #ffff;
    --bs-btn-close-bg: url('/assets/img/x_icon.svg') !important;
  }

  /* Prime P-AutoComplete */

  .p-autocomplete.p-component.p-inputwrapper {
    width: 100%;
    margin-top: 15px;
    border-radius: 15px;
    height: 3.5rem !important;
    color: #434343;
  }

  .p-element.p-autocomplete-input.p-inputtext.p-component {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #4b5563;
    background: #ffffff;
    padding: 0.75rem 0.75rem;
    border: 1px solid #d1d5db;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    appearance: none;
    border-radius: 10px;
  }

  /* Prime p-autocomplete address Checkout flow */
  .custom-autocomplete {
    .p-element.p-autocomplete-input.p-inputtext.p-component {
      display: block;
      width: 100%;
      padding: 0.375rem 0.75rem;
      font-size: 1.25rem;
      font-weight: 400;
      line-height: 1.5;
      color: #000000;
      background: #ffffff;
      padding: 0.75rem 0.75rem;
      border: 1px solid #d1d5db;
      transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
      appearance: none;
      border-radius: 10px;
    }

    >.p-autocomplete.p-component.p-inputwrapper {
      width: 100% !important;
      margin-top: 0px !important;
      border-radius: 15px !important;
      height: 3rem !important;
      color: #434343;
    }
  }

  /* popup modal */
  .text-primary-popup {
    color: #76215D !important;
  }


  html,
  body {
    margin: 0;
    padding: 0;
    border-radius: 0;
    background-color: #EBEBEB;
  }

  /*caret color */
  body {
    caret-color: transparent;
  }

  input,
  textarea,
  select {
    caret-color: auto;
  }
