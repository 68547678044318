.mm-theme {
  &-spacer {
    @include media-breakpoint-up(md) {
      height: 10vh;
    }
  }

  &-checkbox {
    &.form-check-input {
      background-color: white;
      border-color: white;
      border: solid 2px white;
      box-shadow: 0px 0px 0px 2px $primary-color;
      -webkit-box-shadow: 0px 0px 0px 2px $primary-color;
      -moz-box-shadow: 0px 0px 0px 2px $primary-color;

      &:checked {
        background-color: $primary-color;
        background: $primary-strong-color;
      }
    }
  }

  &-btn {

    padding: 0px 25px;
    height: 3rem !important;

    &-rounded {
      height: 3.5rem !important;
    }

    &-oval {
      height: 3.5rem !important;
      border-radius: 100px;
    }

    &-circle {
      height: 3.5rem !important;
      border-radius: 100%;
    }
  }

  &-input {
    background: transparent;
    border-color: #8e8e8e;
    height: 3.5rem !important;
    color: #434343;

    &.form-control {
      border-radius: 10px !important;
    }
  }

  &-background {
    &-primary {
      background-color: $primary-bg-btn !important;

      color: white !important;
    }

    &-secondary {
      background-color: $secondary-color;
      color: white;
    }

    &-secondary:hover {
      background-color: $secondary-color;
      color: white !important;
    }

    &-danger {
      background-color: $danger-color;
      color: white;
    }

    &-warning {
      background-color: $warning-color;
      color: white;
    }

    &-info {
      background-color: $info-color;
      color: white;
    }

    &-light {
      background-color: $light-color;
      color: black;
    }

    &-dark {
      background-color: $dark-color;
      color: white;
    }

    &-purple {
      padding-left: 25px !important;
      padding-right: 25px !important;
      height: 3rem !important;
      background-color: $primary-bg-btn;
      color: white;
    }

    &-purple:hover {
      background-color: $primary-bg-btn;
    }
  }

  &-text {
    &-primary {
      color: $primary-color !important;
    }

    &-primary-strong {
      color: $primary-strong-color;
    }

    &-secondary {
      color: $secondary-color !important;
    }

    &-danger {
      color: $danger-color !important;
    }

    &-warning {
      color: $warning-color !important;
    }

    &-info {
      color: $info-color !important;
    }

    &-light {
      color: $light-color !important;
    }

    &-lighter {
      color: $lighter-color !important;
    }

    &-light-opaque {
      color: $light-opaque-color;
    }

    &-dark {
      color: $dark-color !important;
    }
  }

  &-light-border-color {
    border: solid 1px $light-border-color;
  }

  &-yellow-page-background {
    position: relative;
    background: transparent;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 50%;
      background: $secondary-color url('assets/img/sell/sell-bg.svg') no-repeat top center;
      background-size: 100%;
    }

    img {
      width: 50%;

      @media (min-width: 768px) {
        width: 30%;
      }
    }
  }

  &-primary-page-background {
    position: relative;
    background: transparent;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 60%;
      background: $primary-color url('assets/img/seller-profile/img-background.svg') no-repeat top center;
      background-size: 100%;
    }

    img {
      width: 50%;

      @media (min-width: 768px) {
        width: 30%;
      }
    }
  }
}

/* Text area Quill Theme */
.ql-container.ql-snow {
  border: none !important;
  height: 300px;
}

.ql-toolbar.ql-snow {
  border: none;
  box-sizing: border-box;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  padding: 8px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
  content: "Pequeño";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
  content: "Grande";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
  content: "Gigante";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value=""]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=""]::before {
  content: "Normal";
}

.ql-editor {
  caret-color: auto;
}
