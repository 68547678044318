$primary-color: #842c6b;
$secondary-color: #ffbb00;
$danger-color: #ff0000;
$warning-color: #ffbb00;
$info-color: #00bfff;
$light-color: #636363;
$dark-color: #000000;
$primary-strong-color: #76215d;
$lighter-color: #696969;
$light-opaque-color: #7a7a7a;
$lighter-opaque-color: #CDC5C5;
$light-border-color: rgba(94, 94, 94, 0.3);
$primary-bg-btn: #9e3e84;